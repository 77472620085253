.resize-vertical {
  resize: vertical;
}

.red-text {
  color: red;
}

.w-60-percents {
  width: 60%;
}