.container * {
  box-sizing: border-box;
}

.container body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container h2 {
  font-size: 2rem;
  font-weight: bold;
  font-weight: 400;
  color: #212529;
  text-align: left;
}

.container h5 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0.5rem;
  margin-top: 1rem !important;
}

.container p {
  margin-top: 0;
  margin-bottom: 1rem;
  line-height: 1.5;
}

.container table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
  border-spacing: 2px;
}
.container table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.container table td {
  padding: 0.75rem;
  vertical-align: top;
  border: 1px solid #dee2e6;
  line-height: 1.5;
}

.container table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  text-align: left;
}

.container div > div > :nth-child(14) {
  background-color: #f8f9fa !important;
}
.container div > div > :nth-child(16) {
  background-color: #f8f9fa !important;
}

.container textarea {
  width: 100%;
  height: 100vh; 
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #ced4da;
  color: #495057;
  border-radius: 0.25rem; 
}
